import { ButtonVariantType } from '@dealroadshow/uikit';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import finsightLogo from '@/Framework/assets/logo/finsightLogo.svg';
import { ITenantTheme } from '@/users/domain/vo/ITenantTheme';

export const DEFAULT_THEME: ITenantTheme = {
  loginPage: {
    title: 'Log in',
    subtitle: (
      <>
        Allocate, Deal Roadshow, DealVDR, Evercall, VeriSend, 17g5.com
        <a href={ finsightWebUrl.getOurProductsUrl() } target="_blank"> & more </a>
      </>
    ),
    submitButtonText: 'Log in with FINSIGHT',
  },
  copyright: '© FINSIGHT Group Inc.',
  buttonVariant: ButtonVariantType.firm,
  logo: finsightLogo,
  logoTitle: null,
  isCustomized: false,
  background: {
    animationParams: null,
    backgroundColor: '#EBEBEB',
    backgroundImage: null,
  },
  colors: {
    tileColor: '#FFFFFF',
    primaryTextColor: '#2D2D2D',
    secondaryTextColor: '#8C8C8C',
    linkColor: '#4A76B6',
    inputLabelColor: '#8C8C8C',
    buttonTextColor: '#FFFFFF',
    buttonColor: '#5B3B84',
  },
};
