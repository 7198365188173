import { setCssVariablesToRoot } from '@/Framework/dom/setCssVariablesToRoot';
import { DEFAULT_THEME } from '@/users/application/TenantThemeContext/constants';
import { ITenantTheme } from '@/users/domain/vo/ITenantTheme';

const useTenantTheme = (tenantTheme?: ITenantTheme): ITenantTheme => {
  const currentTheme = tenantTheme ?? DEFAULT_THEME;

  const { backgroundColor, backgroundImage } = currentTheme.background;
  const theme = {
    ...currentTheme.colors,
    backgroundColor,
    backgroundImage: backgroundImage?.url ? `url(${ backgroundImage.url })` : '',
  };
  setCssVariablesToRoot(theme);

  return currentTheme;
};

export default useTenantTheme;
